<template>
  <div ref="container">
    <b-overlay :show="dataLoading" spinner-variant="primary" variants="transparent">
      <apex-charts
        ref="chart"
        type="bar"
        height="350"
        :options="barChart.chartOptions"
        :series="barChart.series"
      />
      <!--    <vs-pagination :total="40" v-model="page" :max="5"></vs-pagination>-->
    </b-overlay>
  </div>
</template>

<script>
import apiRequest from '@/apiRequest'
import store from '@/store'
import moment from 'moment'
import ApexCharts from 'vue-apexcharts'
import colors from '@/layouts/colors'

export default {
  components: {
    ApexCharts,
  },
  props: ['statistic', 'type', 'startDate', 'endDate', 'name', 'keyName', 'countName', 'sort'],
  data() {
    return {
      limit: 15,
      page: 1,
      results: [],
      themeColors: [colors.chart.primary],
      barChart: {
        series: [{
          name: '',
          data: [],
        }],
        chartOptions: {
          colors: [colors.chart.primary],
          chart: {
            toolbar: {
              tools: {
                download: false,
              },
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: [],
          },
        },
      },
      dataLoading: true,
    }
  },
  computed: {
    displayName() {
      return this.name || this.$t('search.statistics.count')
    },
    keyPhrase() {
      return this.keyName || 'phrase'
    },
    valueCount() {
      return this.countName || 'count'
    },
    orderBy() {
      return this.sort || null
    },
    containerName() {
      return this.statistic || 'notFound'
    },
  },
  watch: {
    startDate() {
      this.getStatistics()
    },
    endDate() {
      this.getStatistics()
    },
    page() {
      this.getStatistics()
    },
    type() {
      this.getStatistics()
    },
  },
  mounted() {
    this.getStatistics()
  },
  methods: {
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD hh:mm:ss')
    },
    getStatistics() {
      this.dataLoading = true

      const requestData = {
        granulation: 'day',
        type: this.type,
        start: this.formatDate(this.startDate),
        end: this.formatDate(this.endDate),
        offset: this.limit * (this.page - 1),
        limit: this.limit,
      }
      if (this.orderBy) {
        requestData.orderBy = this.orderBy
      }

      apiRequest
        .post(`search/statistics/${this.statistic}`, requestData)
        .then(result => {
          const series = []
          const axis = []

          this.results = result.data
          for (let i = 0; i < result.data.length; i++) {
            const item = result.data[i]
            series.push(item[this.valueCount])
            axis.push(item[this.keyPhrase])
          }

          this.barChart.series = [{
            data: series,
            name: this.displayName,
          }]
          this.barChart.chartOptions.xaxis.categories = axis
          this.dataLoading = false

          this.$refs.chart.refresh()
        })
        .catch(error => {
          store.commit('notifications/addError', error)
          this.dataLoading = false
        })
    },
  },
}
</script>
