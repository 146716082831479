<template lang="html">
  <div>
    <search-graph-bar
      :start-date="start"
      :end-date="end"
      :type="type"
    />

    <b-card :title="$t('search.headers.searchCount')">
      <dashboard-graph-statistics
        statistic="clicksByDay"
        :start-date="start"
        :end-date="end"
        key-name="date"
        :name="$t('search.statistics.count')"
        :type="type"
      />
    </b-card>

    <b-row align-v="stretch">
      <b-col lg="4" sm="12">
        <b-card :title="$t('search.headers.populars')" lg="4">
          <dashboard-word-cloud
            statistic="populars"
            :start-date="start"
            :end-date="end"
            :type="type"
          />
        </b-card>
      </b-col>
      <b-col lg="4" sm="12">
        <b-card :title="$t('search.headers.typeDistribution')">
          <dashboard-pie-statistics
            statistic="typeDistribution"
            :start-date="start"
            :end-date="end"
            :labels="{
              serp: $t('search.dataSources.serp'),
              autocomplete: $t('search.dataSources.autocomplete'),
              bestseller: $t('search.dataSources.bestseller'),
              popular: $t('search.dataSources.popular')
            }"
          />
        </b-card>
      </b-col>
      <b-col lg="4" sm="12">
        <b-card :title="$t('search.headers.bestConversionAmount')">
          <dashboard-statistics
            statistic="bestConversion"
            :start-date="start"
            :end-date="end"
            count-name="conversion_amount"
            sort="amount"
            :name="$t('search.statistics.saleSum')"
            :type="type"
          />
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6" sm="12">
        <b-card :title="$t('search.headers.populars')">
          <dashboard-statistics
            statistic="populars"
            :start-date="start"
            :end-date="end"
            :type="type"
          />
        </b-card>
      </b-col>

      <b-col lg="6" sm="12">
        <b-card :title="$t('search.headers.zeroSearch')">
          <dashboard-statistics
            statistic="zeroSearch"
            :start-date="start"
            :end-date="end"
            :type="type"
          />
        </b-card>
      </b-col>
      <b-col lg="6" sm="12">
        <b-card :title="$t('search.headers.mostClicked')">
          <dashboard-statistics
            statistic="mostClicked"
            :start-date="start"
            :end-date="end"
            :type="type"
            :name="$t('search.statistics.clicks_count')"
          />
        </b-card>
      </b-col>
      <b-col lg="6" sm="12">
        <b-card :title="$t('search.headers.bestConversion')">
          <dashboard-statistics
            statistic="bestConversion"
            :start-date="start"
            :end-date="end"
            count-name="conversion_count"
            sort="count"
            :name="$t('search.statistics.saleAmount')"
            :type="type"
          />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DashboardStatistics from '@/views/components/search/DashboardStatistics.vue'
import DashboardGraphStatistics from '@/views/components/search/DashboardGraphStatistics.vue'
import DashboardWordCloud from '@/views/components/search/DashboardWordCloud.vue'
import DashboardPieStatistics from '@/views/components/search/DashboardPieStatistics.vue'
import SearchGraphBar from '@/views/components/search/SearchGraphBar.vue'
import store from '@/store'

export default {
  components: {
    DashboardStatistics, DashboardGraphStatistics, DashboardWordCloud, DashboardPieStatistics, SearchGraphBar,
  },
  computed: {
    start() {
      return store.state.search.dateRange.start
    },
    end() {
      return store.state.search.dateRange.end
    },
    type() {
      if (store.state.search.activeDataSource === null) {
        return 'all'
      }
      return store.state.search.activeDataSource.value
    },
  },
}
</script>
