<template>
  <b-container fluid>
    <b-row>
      <b-col lg="4" sm="12">
        <b-card>
          <div ref="graph1">
            <search-mini-graph
              color="#ee3569"
              icon="search"
              :title="$t('search.searchGraphBar.headers.searchCount')"
              :tooltip="$t('search.searchGraphBar.tooltip.searchCount')"
              :graphs-data="graphsData"
              graphs-data-column="views"
              :data-loading="dataLoading"
              suffix=""
            />
          </div>
        </b-card>
      </b-col>

      <b-col lg="4" sm="12">
        <b-card>
          <div ref="graph2">
            <search-mini-graph
              color="#fcde05"
              icon="mouse"
              :title="$t('search.searchGraphBar.headers.searchClickCount')"
              :tooltip="$t('search.searchGraphBar.tooltip.searchClickCount')"
              :graphs-data="graphsData"
              graphs-data-column="clicks"
              :data-loading="dataLoading"
              suffix=""
            />
          </div>
        </b-card>
      </b-col>

      <b-col lg="4" sm="12">
        <b-card>
          <div ref="graph3">
            <search-mini-graph
              color="#2ABB9D"
              icon="timeline"
              :title="$t('search.searchGraphBar.headers.searchClickThroughRate')"
              :tooltip="$t('search.searchGraphBar.tooltip.searchClickThroughRate')"
              :graphs-data="graphsData"
              graphs-data-column="ctr"
              :data-loading="dataLoading"
              suffix="%"
            />
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="4" sm="12">
        <b-card>
          <div ref="graph4">
            <search-mini-graph
              color="#ee3569"
              icon="shopping_cart"
              :title="$t('search.searchGraphBar.headers.salesAmount')"
              :tooltip="$t('search.searchGraphBar.tooltip.salesAmount')"
              :graphs-data="graphsData"
              graphs-data-column="sales_count"
              :data-loading="dataLoading"
            />
          </div>
        </b-card>
      </b-col>

      <b-col lg="4" sm="12">
        <b-card>
          <div ref="graph5">
            <search-mini-graph
              color="#fcde05"
              icon="monetization_on"
              :title="$t('search.searchGraphBar.headers.salesTotal')"
              :tooltip="$t('search.searchGraphBar.tooltip.salesTotal')"
              :graphs-data="graphsData"
              graphs-data-column="sales_total"
              :data-loading="dataLoading"
            /></div>
        </b-card>
      </b-col>

      <b-col lg="4" sm="12">
        <b-card>
          <div ref="graph6">
            <search-mini-graph
              color="#2ABB9D"
              icon="trending_up"
              :title="$t('search.searchGraphBar.headers.salesConversion')"
              :tooltip="$t('search.searchGraphBar.tooltip.salesConversion')"
              :graphs-data="graphsData"
              graphs-data-column="sales_conversion"
              suffix="%"
              :data-loading="dataLoading"
            />
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import SearchMiniGraph from '@/views/components/search/SearchMiniGraph.vue'
import moment from 'moment'
import apiRequest from '@/apiRequest'
import store from '@/store'

export default {
  components: {
    SearchMiniGraph,
  },
  props: ['type', 'startDate', 'endDate'],
  data() {
    return {
      graphsData: {},
      dataLoading: true,
    }
  },
  watch: {
    startDate() {
      this.getGraphsData()
    },
    endDate() {
      this.getGraphsData()
    },
    type() {
      this.getGraphsData()
    },
  },
  mounted() {
    this.getGraphsData()
  },
  methods: {
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD hh:mm:ss')
    },
    getGraphsData() {
      this.dataLoading = true
      apiRequest
        .post('search/statistics/generalPerformance', {
          type: this.type,
          start: this.formatDate(this.startDate),
          end: this.formatDate(this.endDate),
        })
        .then(result => {
          this.dataLoading = false
          this.graphsData = result.data
        })
        .catch(error => {
          store.commit('notifications/addError', error)
          this.dataLoading = false
        })
    },
  },
}
</script>
