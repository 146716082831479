<template>
  <div ref="container">
    <b-overlay :show="dataLoading" spinner-variant="primary" variants="transparent">
      <wordcloud
        :data="phrases"
        name-key="phrase"
        value-key="count"
        :tooltip-parameter-name="$t('search.statistics.phrase')"
        :tooltip-value-name="$t('search.statistics.count')"
        :rotate="rotate"
        :color="colors"
        :show-tooltip="true"
        :word-click="onWordClick"
      />
    </b-overlay>
  </div>
</template>

<script>
import apiRequest from '@/apiRequest'
import store from '@/store'
import moment from 'moment'
import wordcloud from '@/views/components/word-cloud/WordCloud.vue'
import colors from '@/layouts/colors'

export default {
  components: {
    wordcloud,
  },
  props: ['statistic', 'type', 'startDate', 'endDate', 'name'],
  data() {
    return {
      phrases: [],
      rotate: { from: 0, to: 0, numOfOrientation: 1 },
      colors: colors.chart.primaryShade,
      fontScale: 'log',
      dataLoading: true,
    }
  },
  computed: {
    displayName() {
      return this.name || this.$t('search.statistics.count')
    },
    onWordClick() {
      return function () {}
    },
  },
  watch: {
    startDate() {
      this.getPhrases()
    },
    endDate() {
      this.getPhrases()
    },
    type() {
      this.getPhrases()
    },
  },
  mounted() {
    this.getPhrases()
  },
  methods: {
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD hh:mm:ss')
    },
    /**
       * @returns []
       */
    getPhrases() {
      this.dataLoading = true
      apiRequest
        .post(`search/statistics/${this.statistic}`, {
          granulation: 'day',
          type: this.type,
          start: this.formatDate(this.startDate),
          end: this.formatDate(this.endDate),
          offset: 0,
          limit: 30,
        })
        .then(result => {
          this.dataLoading = false
          this.phrases = result.data
        })
        .catch(error => {
          store.commit('notifications/addError', error)
          this.dataLoading = false
        })
    },
  },
}
</script>

<style>
  div.tooltip {
    font-size: .8rem;
    line-height: 1.2em;
    min-width: 11rem;
    height: auto;
  }
</style>
