<template>
  <div ref="container">
    <b-overlay :show="dataLoading" spinner-variant="primary" variants="transparent">
      <apex-charts
        ref="chart"
        type="pie"
        height="400"
        :options="pieChart.chartOptions"
        :series="pieChart.series"
      />
    </b-overlay>
  </div>
</template>

<script>
import apiRequest from '@/apiRequest'
import store from '@/store'
import moment from 'moment'
import ApexCharts from 'vue-apexcharts'
import colors from '@/layouts/colors'

export default {
  components: {
    ApexCharts,
  },
  props: ['statistic', 'startDate', 'endDate', 'name', 'labels', 'keyName', 'countName'],
  data() {
    return {
      pieChart: {
        series: [],
        chartOptions: {
          colors: colors.pieChart,
          legend: {
            position: 'bottom',
          },
          responsive: [{
            breakpoint: 480,
          }],
        },
      },
      dataLoading: true,
    }
  },
  computed: {
    displayName() {
      return this.name
    },
    labelPhrase() {
      return this.keyName || 'type'
    },
    valueCount() {
      return this.countName || 'count'
    },
    chartLabels() {
      return this.labels || null
    },
  },
  watch: {
    startDate() {
      this.getStatistics()
    },
    endDate() {
      this.getStatistics()
    },
  },
  mounted() {
    this.getStatistics()
  },
  methods: {
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD hh:mm:ss')
    },
    getStatistics() {
      this.dataLoading = true
      apiRequest
        .post(`search/statistics/${this.statistic}`, {
          start: this.formatDate(this.startDate),
          end: this.formatDate(this.endDate),
        })
        .then(result => {
          const dataSeries = []
          const labels = []

          for (let i = 0; i < result.data.length; i++) {
            const item = result.data[i]
            dataSeries.push(item[this.valueCount])
            labels.push(this.labels[item.type])
          }

          this.pieChart.series = dataSeries
          this.pieChart.chartOptions.labels = labels
          this.dataLoading = false
          this.$refs.chart.refresh()
        })
        .catch(error => {
          store.commit('notifications/addError', error)
          this.dataLoading = false
        })
    },
  },
}
</script>
