<template>
  <b-row no-gutters>
    <div ref="dashboardGraphStatistics" class="w-100">
      <b-overlay :show="dataLoading" spinner-variant="primary" variants="transparent">
        <apex-charts
          ref="chart"
          type="area"
          height="350"
          width="100%"
          :options="barChart.chartOptions"
          :series="barChart.series"
        />
      <!--    <vs-pagination :total="40" v-model="page" :max="5"></vs-pagination>-->
      </b-overlay>
    </div>
  </b-row>
</template>

<script>
import apiRequest from '@/apiRequest'
import store from '@/store'
import moment from 'moment'
import ApexCharts from 'vue-apexcharts'
import colors from '@/layouts/colors'

export default {
  components: {
    ApexCharts,
  },
  props: ['statistic', 'type', 'startDate', 'endDate', 'columns', 'name', 'keyName', 'countName'],
  data() {
    return {
      limit: 150,
      page: 1,
      results: [],
      themeColors: [colors.chart.primary],
      barChart: {
        series: [{
          name: '',
          data: [],
        }],
        chartOptions: {
          colors: [colors.chart.primary],
          chart: {
            toolbar: {
              tools: {
                download: false,
              },
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          fill: {
            gradient: {
              enabled: true,
              opacityFrom: 0.55,
              opacityTo: 0,
            },
          },
          markers: {
            size: 3,
            colors: [colors.chart.primaryLighter],
            strokeColor: colors.chart.primaryLighter,
            strokeWidth: 2,
          },
          xaxis: {
            type: 'datetime',
            categories: [],
          },
        },
      },
      dataLoading: true,
    }
  },
  computed: {
    displayName() {
      return this.name || this.$t('search.statistics.count')
    },
    keyPhrase() {
      return this.keyName || 'phrase'
    },
    valueCount() {
      return this.countName || 'count'
    },
  },
  watch: {
    startDate() {
      this.getStatistics()
    },
    endDate() {
      this.getStatistics()
    },
    page() {
      this.getStatistics()
    },
    type() {
      this.getStatistics()
    },
  },
  mounted() {
    this.getStatistics()
  },
  methods: {
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD hh:mm:ss')
    },
    formatDayDate(date) {
      return moment(date).format('YYYY-MM-DD')
    },
    getStatistics() {
      this.dataLoading = true
      apiRequest
        .post(`search/statistics/${this.statistic}`, {
          granulation: 'day',
          type: this.type,
          start: this.formatDate(this.startDate),
          end: this.formatDate(this.endDate),
          offset: this.limit * (this.page - 1),
          limit: this.limit,
        })
        .then(result => {
          const series = []
          const axis = []

          this.results = result.data
          for (let i = 0; i < result.data.length; i++) {
            const item = result.data[i]
            series.push(item[this.valueCount])
            axis.push(this.formatDayDate(item[this.keyPhrase]))
          }

          this.barChart.series = [{
            data: series,
            name: this.displayName,
          }]

          this.barChart.chartOptions.xaxis.categories = axis
          this.dataLoading = false

          this.$refs.chart.refresh()
        })
        .catch(error => {
          store.commit('notifications/addError', error)
          this.dataLoading = false
        })
    },
  },
}
</script>

<style scoped>

</style>
